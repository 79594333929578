<template>
  <div class="container login-container">
      <form class="login-form" @submit.prevent.stop="submitForm()" name="login">
        <div class="login-top">
          <ButtonComponent
          btnStyle="link-accent"
          behavior="button"
          :icon="{name: 'arrow-left'}"
          @click.native="historyGoBack"
          type="button"
          >Zurück</ButtonComponent
        >
        </div>
        <h1 class="login-title">Bitte loggen Sie sich ein</h1>
        <div class="form-group form-group--icon-l" v-if="!twoFactorAuth">
            <Icon name="mail" :width="20" class="icon icon-l"></Icon>
            <b-form-input type="email" id="email" class="form-trol" v-model="user.email" placeholder="E-Mail" required></b-form-input>
        </div>
        <div class="form-group form-group--icon-l form-group--icon-r" v-if="!twoFactorAuth">
            <Icon name="lock" :width="20" class="icon icon-l"></Icon>
            <b-form-input :type="!showPass ? 'password' : 'text' " id="password" class="form-trol" v-model="user.password" placeholder="Passwort" required></b-form-input>
            <Icon name="eye" :width="17" class="icon icon-r" :class="{'icon-primary': showPass}" @click.native="() => {showPass = !showPass}"></Icon>
        </div>
        <div class="form-group" v-if="twoFactorAuth">
            <label for="token">SMS Token</label>
            <input class="form-control" type="text" name="token" id="token" v-model="user.token">
        </div>
        
        <ButtonComponent
          btnStyle="accent"
          behavior="button"
          class="submit-btn"
          >Anmelden</ButtonComponent>
        <div class="login-form__bottom-links">
          <ButtonComponent
            btnStyle="link-primary"
            behavior="internalLink"
            link="/login/forgot"
            type="button"
            >Passwort vergessen?</ButtonComponent>
          <ButtonComponent
            btnStyle="link-primary"
            behavior="externalLink"
            :link="registerLink"
            type="button"
            >Registrieren?</ButtonComponent>
        </div>
      </form>
  </div>
</template>


<script>
import ButtonComponent from '@/components/reusable/ButtonComponent'
import Icon from '@/components/Icon.vue'

  export default {
    name: 'Login',

    components: {ButtonComponent, Icon},

    metaInfo: {
      title: 'Login'
    },

    props: ['user'],

    data() {
      return {
        twoFactorAuth: false,
        showPass: false
      }
    },

    computed: {
      registerLink() {
        return process.env.VUE_APP_CLIENT_BASE + 'registrieren'
      }
    },

    methods: {
      submitForm: function() {
        const {email, password, token} = this.user;
        const payload = {email, password};

        if (this.twoFactorAuth) payload.token = token;
        this.$store
          .dispatch('LOGIN', payload)
          .then(() => {
            const redirect = this.$route.query && this.$route.query.redirect ? this.$route.query.redirect : '/';
            return this.$router.push(redirect);
          })
          .catch((res) => {
            if (res.data && res.data.error && res.data.error.message === 'need_2f') {
              this.twoFactorAuth = true;
              setTimeout(function () {
                document.getElementById('token').focus()
              }.bind(this), 300)
            }
          });
      },

      historyGoBack() {
        window?.history.go(-1)
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '../../scss/variables';
@import '../../scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.login-page {
  background: #15192d;
  min-height: 100vh;
  // width: 100vw;
  padding-top: 100px;
  padding-bottom: 50px;

  .cd-logo {
    margin-bottom: 3.5rem;
  }

  .login-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.login-title {
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 2rem;
}

.login-form {
  background: #fff;
  border-radius: 0.5rem;
  color: #454545;
  padding: 2rem 2.5rem 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include media-breakpoint-down(xs) {
    padding: 2rem 1.5rem;
  }

  .login-top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;

    ::v-deep button {
      svg {
        margin-left: -0.5rem;
        margin-right: 0.25rem;
      }
    }
  }

  .form-group {
    width: 100%;
    margin-bottom: 1.5rem;

    &--icon-l {
      position: relative;

      .icon-l {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 14px;

        ::v-deep svg {
          color: $orangeTone;
        }
      }

      .form-control {
        padding-left: 2.75rem;
      }
    }

    &--icon-r {
      position: relative;

      .icon-r {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;

        ::v-deep svg {
          color: #454545;
        }
      }

      .form-control {
        padding-right: 2.75rem;
      }
    }

    .icon-primary {
      ::v-deep svg {
        color: $primary;
      }
    }
  }

  .form-control {
    border: 1px solid #ced9dfe5;
    background: #CED9DF33;
    height: 40px;
      font-size:  0.9rem;
  }

  .input-group-text {
    background: #CED9DF33;
    border-right: none;
    padding-right: 0;
  }

  .input-group-prepend {
    margin-right: 0;

    & + .form-control {
      border-left: none;
    }
  }

  .submit-btn {
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    width: 100%;
  }

  &__bottom-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
</style>


