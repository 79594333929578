import Vue from 'vue'
import Router from 'vue-router'

// pages
import Authenticate from '@/pages/login/Authenticate';
import CreateAccount from '@/pages/login/CreateAccount';
import Forgot from '@/pages/login/Forgot';
import Login from '@/pages/login/Login';
import Marktstudien from '@/pages/Marktstudien';
import Offer from '@/pages/offers/Offer';
import Offers from '@/pages/offers/Offers';
import Reset from '@/pages/login/Reset';
import Settings from '@/pages/Settings';
import SSO from '@/pages/login/SSO';
import Webinars from '@/pages/Webinars';
import CyberAkademie from '@/pages/CyberAkademie';
import PhishingVouchers from '@/pages/PhishingVouchers';
import Support from '@/pages/support/index';

// components
import Admin from '@/components/Admin';
import Affiliate from '@/components/Affiliate';
import Beginnen from '@/components/Beginnen';
import BeginnenLP from '@/components/BeginnenLP';
import BeginnenSP from '@/components/BeginnenSP';
import Beratungstool from '@/components/Beratungstool';
import CustomerLetters from '@/components/CustomerLetters';
import Finish from '@/components/Finish';
import Gesprachsleitfaden from '@/components/Gesprachsleitfaden';
import Kontakt from '@/components/Kontakt';
import Main from '@/components/Main';
import Presentations from '@/components/Presentations';
import Presse from '@/components/Presse';
import PresseArticle from '@/components/PresseArticle';
import Risikofragen from '@/components/Risikofragen';
import SalesMaterials from '@/components/SalesMaterials';
import Education from '@/components/Education';
import Schadenbeispiele from '@/components/Schadenbeispiele';
import Vectors from '@/components/Vectors';
import Versicherungsbedingungen from '@/components/Versicherungsbedingungen';
import Videos from '@/components/Videos';

import MyTrainings from '@/pages/trainings/MyTrainings.vue';
import MyTraining from '@/pages/trainings/MyTraining.vue';
import MyTrainingQuiz from '@/pages/trainings/MyTrainingQuiz.vue';
import MyTrainingModule from '@/pages/trainings/MyTrainingModule.vue';

import TrackingAPI from '@/api/analytics.js';
import store from '../store/index'
import {getCookie, setCookie} from '@/helpers/cookie.js'

Vue.use(Router);

let router = new Router({
  mode: 'history',
  routes: [
    {
      name: 'CreateAccount',
      path: '/login/create',
      component: CreateAccount,
      meta: {
        requiresNoAuth: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        requiresNoAuth: true
      },
      children: [
        {
          name: 'Authenticate',
          path: 'authenticate',
          component: Authenticate
        },
        {
          name: 'Forgot',
          path: 'forgot',
          component: Forgot
        },
        {
          name: 'Reset',
          path: 'reset/:tokenId',
          component: Reset
        },
        {
          name: 'Single Sign On',
          path: 'sso',
          component: SSO
        }
      ]
    },
    {
      name: 'Main',
      path: '/',
      component: Main
    },
    {
      name: 'Einstellungen',
      path: '/settings',
      component: Settings
    },
    {
      name: 'Mitarbeiterschulung',
      path: '/mitarbeiterschulung',
      component: MyTrainings
    },
    {
      path: '/mitarbeiterschulung/:slug',
      name: 'Mitarbeiterschulung',
      component: MyTraining,
      beforeEnter: async (to, from, next) => {
        await Promise.all([
          store.dispatch('GET_COURSE_DATA', to.params.slug),
          store.dispatch('GET_COURSE_PROGRESS', to.params.slug)
        ]);
        next();
      },
      children: [
        {
          path: 'quiz/:step',
          name: 'training-quiz',
          meta: {
            quiz: true
          },
          component: MyTrainingQuiz
        },
        {
          path: ':slide?/:step?',
          name: 'training-module',
          meta: {
            module: true
          },
          component: MyTrainingModule
        }
      ]
    },
    {
      name: 'Erklarvideos',
      path: '/erklarvideos',
      component: Videos,
      meta: {
        title: 'Erklärvideos'
      }
    },
    {
      name: 'Webinare',
      path: '/webinare',
      component: Webinars,
      meta: {
        title: 'Webinare'
      },
      beforeEnter: async (to, from, next) => {
        await store.dispatch('GET_WEBINAR_LIST')
        next();
      },
    },
    {
      name: 'Cyber-Akademie',
      path: '/cyber-akademie',
      component: CyberAkademie,
      meta: {
        title: 'Cyber-Akademie'
      }
    },
    {
      name: 'Support',
      path: '/support',
      component: Support,
      meta: {
        title: 'Support'
      }
    },
    {
      name: 'Weiterbildung',
      path: '/weiterbildung',
      component: Education,
      meta: {
        title: 'Weiterbildung'
      }
    },
    {
      name: 'Bedingungen und Formulare',
      path: '/bedingungen-und-formulare/:id',
      component: Versicherungsbedingungen,
      beforeEnter: async (to, from, next) => {
        if(!store.state.checkout.application.isInitialDataLoaded) {
          store.commit('SET_INIT_STATUS', 'bedingungen');
          await store.dispatch('GET_INITIAL_DATA')
        }
        next();
      },
    },
    {
      name: 'Vertriebsmaterialien',
      path: '/vertriebsmaterialien',
      component: SalesMaterials,
      children: [
        {
          name: 'Guidelines',
          path: 'gesprachsleitfaden',
          component: Gesprachsleitfaden,
          beforeEnter: async (to, from, next) => {
            await store.dispatch('GET_GUIDELINES')
            next();
          },
        },
        {
          name: 'Kundenanschreiben',
          path: 'kundenanschreiben',
          component: CustomerLetters
        },
        {
          name: 'Prasentationen',
          path: 'prasentationen',
          component: Presentations
        },
        {
          path: 'schadenbeispiele/:id',
          name: 'Schadenbeispiele',
          component: Schadenbeispiele
        },
        {
          path: '/marktstudien',
          name: 'Marktstudien',
          component: Marktstudien,
          children: [{
            path: ':slug',
            name: 'MarktstudienLP'
          }]
        }]
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin
    },
    {
      path: '/presse',
      name: 'Presse',
      component: Presse,
      beforeEnter: async (to, from, next) => {
        await store.dispatch('GET_PRESSE_ARTICLES')
        next();
      },
    },
    {
      path: '/presse/:id',
      name: 'Presse article',
      component: PresseArticle,
    },
    {
      path: '/kontakt',
      name: 'Kontakt',
      component: Kontakt
    },
    {
      path: '/beginnen',
      name: 'Beginnen',
      component: Beginnen,
      children: [
        {
          path: '/beratungstool',
          name: 'Beratungstool',
          component: Beratungstool,
          meta: {
            title: 'Beratungstool',
            slug: 'standard-page',
            noTitles: true,
            backPageName: {
              name: 'Main'
            }
          }
        },
        {
          path: 'risikofragen/:id',
          name: 'Risikofragen',
          component: Risikofragen,
          meta: {
            title: '',
            description: '',
            nextPageName: 'Vectors',
            backPageName: 'Beratungstool|:id'
          }
        },
        {
          path: 'risikofragen2/:id',
          name: 'Risikofragen2',
          component: Risikofragen,
          meta: {
            title: '',
            description: '',
            nextPageName: 'Vectors',
            backPageName: 'Beratungstool|:id'
          }
        },
        {
          name: 'Vectors',
          path: 'vectors',
          component: Vectors,
          meta: {
            title: 'Angriffswege',
            disableMounting: true,
            noTitles: true,
            backPageName: {
              name: 'Risikofragen',
              params: {id: '1'}
            },
            nextPageName: 'Finish'
          },
          children: [{
            name: 'BeginnenLP',
            path: ':id',
            component: BeginnenLP,
            children: [{
              name: 'BeginnenSP',
              path: 'tab/:tabId',
              component: BeginnenSP,
              meta: {
                disableMounting: true
              }
            }]
          }]
        },
        {
          path: 'finish',
          name: 'Finish',
          component: Finish,
          meta: {
            nextPageName: false,
            backPageName: 'Vectors',
            noTitles: true,
            slug: 'standard-page'
          }
        }
      ]
    },
    {
      path: '/affiliate',
      name: 'Affiliate',
      component: Affiliate
    },
    {
      path: '/phishing-vouchers',
      name: 'PhishingVouchers',
      component: PhishingVouchers
    },
    {
      path: '/offers',
      name: 'Angebote',
      component: Offers,
      beforeEnter: async (to, from, next) => {
        if(!store.state.checkout.application.isInitialDataLoaded) {
          store.commit('SET_INIT_STATUS', 'offers');
          await store.dispatch('GET_INITIAL_DATA')
        }
        next();
      },
    },
    {
      name: 'AngeboteDetail',
      path: '/offers/:offerId',
      component: Offer,
      beforeEnter: async (to, from, next) => {
        store.commit('SET_INIT_STATUS', 'one-offer');
        if(!store.state.checkout.application.isInitialDataLoaded) {
          await store.dispatch('GET_INITIAL_DATA')
        }
        next();
      },
    }
  ]
});

router.beforeEach((to, from, next) => {

  // block if AVV isn't checked
  const blockExceptionPaths = ['/settings']
  const isBlockedByAVV = 
    to.path !== '/'
    && !store.state.user.orderProcessingChecked
    && from.path !== '/login/authenticate'
    && !blockExceptionPaths.includes(to.path)
    && store.state.auth.token.length > 0
  isBlockedByAVV ? next('/') : ''
  // /block if AVV isn't checked

  const requiresNoAuth = to.matched.some(view => view.meta.requiresNoAuth);
  let sessionValue;

  // check if countryInterface is setted
  const agencyKey =
    store.state && store.state.user && store.state.user.brokerAgency
      ? store.state.user.brokerAgency.key
      : undefined
  const countryInterface = store.state.checkout ? store.state.checkout.application.countryInterface : undefined
  !countryInterface || !countryInterface.value || countryInterface.setFrom !== 'broker-portal'
    ? store.commit('SET_COUNTRY_INTERFACE', {agencyKey, setFrom: 'broker-portal'})
    : ''

    // no offer popup
  if (from.path === '/login/authenticate' && store.state.auth.token.length) {
    let stopNumber = 3
    let loginCounter = Number(getCookie('login-counter')) || 0
    setCookie('login-counter', ++loginCounter)

    if (loginCounter > stopNumber) {
      store.commit('STOP_SHOWING_NO_OFFER_POPUP')
    } else {
      store.commit('START_SHOWING_NO_OFFER_POPUP')
    }
  }

  if (!requiresNoAuth) {
    sessionValue = sessionStorage.getItem('cd-broker');
    sessionValue = sessionValue ? JSON.parse(sessionValue) : {};

    if (!(sessionValue.auth && sessionValue.auth.token)) {
      next({
        path: '/login/authenticate',
        query: {redirect: to.fullPath}
      });
    } else {
      if (store.state.user.isActive !== true) {
        store.commit('TOGGLE_INACTIVE_POPUP', true)
      } else {
        store.commit('TOGGLE_INACTIVE_POPUP', false)
      }

      if (!store.getters.isProfileValid && to.name !== 'Einstellungen') {
        next({
          path: '/settings'
        });
      } else {
        if (to.path.indexOf('/versicherung') === -1) {
          TrackingAPI.track(to.path);
        }
        store.dispatch('GET_NOTIFICATIONS')
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
